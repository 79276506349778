import React from "react"
import SiteLayout from "../components/SiteLayout";

export default class LoginLink extends React.Component<any, any> {
    
    componentDidMount() {
        function getParameterByName(name) {
            let url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        var url = getParameterByName('url'); // "lorem"
        console.log("Redirecting to: " + url);
        window.location.replace(url);
    }

    render() {
        return (
            <div>
                Redirecting to login...
            </div>
        )
    }
}
